//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDetailEdm } from './customerdetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerDetailEdmEntityConfig = {
  name: 'CustomerDetailEdm',
  keys: [{name: 'customerAgencyGuid'}],
  fields: {
    customerGuid: {type: 'Edm.Guid', nullable: false},
    customerAgencyGuid: {type: 'Edm.Guid', nullable: false},
    customerDesc: {type: 'Edm.String'},
    agencyDesc: {type: 'Edm.String'},
    customerStatus: {type: 'Edm.Int16', nullable: false},
    verificationOutcome: {type: 'Edm.String'},
    notFound: {type: 'Edm.Boolean'},
    suspended: {type: 'Edm.Boolean'},
    reported: {type: 'Edm.Boolean'},
    legal: {type: 'Edm.Boolean'},
    creditLimit: {type: 'Edm.Double'},
    debt: {type: 'Edm.Double'},
    exposure: {type: 'Edm.Double'},
    exposureCap: {type: 'Edm.Double'},
    creditLimitReviewDate: {type: 'Edm.DateTime'},
    customerAgencyStatus: {type: 'Edm.Int16', nullable: false},
    customerType: {type: 'Edm.Int16'},
    customerSubType: {type: 'Edm.String'},
    descAndType: {type: 'Edm.String'},
    isCharity: {type: 'Edm.Boolean'},
    totalSearchCount: {type: 'Edm.Int32'}
  }
} as StructuredTypeConfig<CustomerDetailEdm>;
//#endregion